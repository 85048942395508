import React, {useState} from 'react';
import Sectitle from "./Title/Sectitle";

export default function Contacts2() {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailStatus, setEmailStatus] = useState('');

  const handleChange = e => {
    switch (e.target.name) {
      case "name":
        setName(e.target.value);
        break;
      case "subject":
        setSubject(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name,
      subject,
      email,
      message
    };

    postData(
        "https://jrxjqu14d8.execute-api.eu-west-2.amazonaws.com/prod/contact-us",
        data)
    .then((data) => {
      console.log("Received response.");
      setEmailStatus("success");
    })
    .catch(e => {
      console.log("Error: " + e);
      setEmailStatus("error");
    });
  };

  async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data)
    });
    return await response; // parses JSON response into native JavaScript objects
  }

  return (
      <section className="contact_info_area sec_pad bg_color" id="contact">
        <div className="container">
          <Sectitle sClass="sec_title text-center mb_70" Title="Contact Us"
                    tClass="t_color3"
                    TitleP="Let us turn your ideas into business changing software solutions."/>

          <div className="row">
            <div className="col-lg-3">
              {/* <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Office
                  Address</h6>
                <p className="f_400 f_size_15">Melbourne’s GPO 434 Bourke St.
                  Dhaka VIC 3074, Australia</p>
              </div> */}
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Contact
                  Info</h6>
                {/* <p className="f_400 f_size_15"><span
                    className="f_400 t_color3">Phone:</span> <a
                    href="tel:3024437488">(+096) 302 443 7488</a></p>
                <p className="f_400 f_size_15"><span
                    className="f_400 t_color3">Fax:</span> <a
                    href="tel:3024437488">(+096) 204 353 6684</a></p> */}
                <p className="f_400 f_size_15"><span
                    className="f_400 t_color3">Email:</span> <a
                    href="mailto:contact@celestial-solutions.co.uk">contact@celestial-solutions.co.uk</a>
                </p>
              </div>
            </div>
            <div className="contact_form col-lg-9">
              <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Leave
                a Message</h2>
              <form onSubmit={handleSubmit} className="contact_form_box"
                    method="post" id="contactForm">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input type="text" id="name" name="name"
                             placeholder="Your Name"
                             onChange={handleChange} required/>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input type="email" name="email" id="email"
                             placeholder="Your Email"
                             onChange={handleChange} required/>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <input type="text" id="subject" name="subject"
                             placeholder="Subject"
                             onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                        <textarea onChange={handleChange} name="message"
                                  id="message" cols="30" rows="10"
                                  placeholder="Enter Your Message . . ."
                                  required/>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn_three">Send Message
                </button>
              </form>
              {emailStatus === "success"
                  ?
                  <div id="success">Your message was successfully sent! We
                    will get back to you as soon as possible. </div>
                  : emailStatus === "error"
                      ? <div id="error">Opps! Sometime went wrong. Please try
                        again.</div>
                      : null}
            </div>
          </div>

        </div>
      </section>
  )
}
